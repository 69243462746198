import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
    <Layout>
        <Seo title="404 | Not found" />
        <menu className="back-menu ">
            <Link to="/" style={{ color: 'rgba(9, 236, 77, 1)' }}>
                <i className="bi bi-arrow-left" style={{ fontSize: 18 }}></i> Back home
            </Link>
        </menu>
        <h1>404: Not Found</h1>
        <p>You look lost... the sadness.</p>
    </Layout>
)

export default NotFoundPage
